@import '../utils/_mixins';
@import '../utils/_variables';

/*MAIN*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, 
form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer,
header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0; padding: 0; border: 0; font-size: 100%; 
}

html {font-size: $base-font-size!important;}
body {
	margin: 0px;
	padding: 0px;
	font-family: $base-font-family;
	background: $base-bg;
	height: 100%;
	color:$text-color;
	font-weight: 300;
	line-height: $base-line-height;
	position: relative;
	font-size: $base-font-size;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
	display: block;
}
blockquote, q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-width: 0px;
	padding: 0px;
	margin: 0px;
}

html {
	height: 100%;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
}

input,
textarea {
	color: #333;
	font-family: $base-font-family;
	outline: none;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	-webkit-appearance: none;
}

input[type="button"],
input[type="submit"],
button {
	cursor: pointer
}

td {
	margin: 0px;
	padding: 0px;
}

form {
	padding: 0px;
	margin: 0px;
}

a {
	color: $link-color;
	@include transition($default-transition);
	text-decoration: none; 
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);   
	&:hover {color:#860986;}   
    &:active, &:focus {}   
}

a,
span,
div,
button {
	outline: none!important
}
img {vertical-align:middle;max-width:100%;}

input[type=submit],
input[type=button],
button {
	-webkit-appearance: none;
	outline: none;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.clearfix:after,
.wrapper:after{
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}
