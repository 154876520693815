/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
.footer {	
	text-align: left;
	width:100%;
	min-width: $min-width;
	order: 3; 
	background: rgb(1, 19, 35);
    -webkit-order: 3;position: relative; 
    padding: 45px 0 41px;
    &_cols {position: relative;}
    &_cont {padding: 0px 220px 0px;text-align: center;} 
    &_copy {font-size: 12px;font-weight: 400; line-height: 1.75; color: rgb(183, 183, 183);}
    &_logo { position: absolute; left:0; top:50%; transform: translateY(-50%);
    	a {display: block;font-size: 0;} 
        img {max-height: 45px;} 
    }
    &_nav { margin: 0px 0 8px 0px;
    	ul {list-style: none; display: flex; justify-content: center; gap:16px;
    		li {font-size: 12px;font-weight: 700;line-height: 26px;              
    			a {color: #fff;
    				&:hover {color: rgb(174, 11, 114);} 
    			}
    		} 
    	}
    }
}

@media screen and (min-width: 1360px) { 
    .footer .wrapper {max-width: 1338px; padding:0 10px;}
}
@media screen and (max-width: 1149px) {  
    .footer { padding: 20px 0 22px; 
        &_logo {position: relative; top:0;margin:0 auto; text-align: center;transform: translateY(0%);}
        &_cont {padding: 16px 0 0;}
        &_nav { margin: 0px 0 5px 0px;}
    }

} 
@media screen and (max-width: 1023px) { 
    .footer_logo {
        img {max-height: 35px;}
    }

}
@media screen and (max-width: 767px) {  
    .footer {padding: 30px 0 34px; 
        &_nav {margin: 0px 0 8px 0px;
            ul {margin:4px auto; width: 88.5%; flex-wrap: wrap; gap:0; justify-content: space-between; text-align: left;
                li {margin-bottom: 11px; font-family: "Inter",sans-serif; width:50%;font-size: 14px; font-weight: 400; 
                    &:nth-child(even) {text-align: right;}
                }
            }
        }
        &_copy {margin:0 -30px;border-top:1px solid rgba(128,128,128,0.5); padding: 31px 30px 0;
            span {display: block;}
        }
    }
	

}
@media screen and (max-width: 399px) { 
    .footer_nav ul {width:100%;}
}