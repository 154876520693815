
/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
.header {
	width:100%; 
	order: 1;	
    -webkit-order: 1;  
    left: 0;  right:0;          
    z-index: 150;   
    top:0; width:100%; position:absolute;  
    background: rgba(3,4,5,0.38); color:#fff;         
    &.fix {} 
    &_cols {height: 85px; display: flex; justify-content: space-between; align-items: center; position: relative;}
    &_logo {margin-bottom: 1px; position: relative; margin-right:auto;   z-index: 11;
        a {font-weight: 500;font-size: 15px;color:#fff;text-transform: uppercase;line-height: 173%;letter-spacing: 0.01em; display: block;
            img {max-height:45px;}
        }
    }
    &_nav {margin-right: 16px;
        ul {list-style: none; display: flex; gap:16px;
            li {  position:relative; display: flex; align-items: center;
                a {padding-top: 12px; border-bottom: 6px solid transparent; font-size: 14px;height: 85px; color: currentColor;line-height: 20px;font-weight: 400;display: flex;align-items: center; position: relative;
                    &:hover {color: currentColor; border-color:#a7039f;}
                    &:active, &:focus { border-color:#a7039f; }
                } 
                &.active a, &.current-menu-item a {border-color:#a7039f; }
                &.li-cats {margin-top: 3px; 
                    a {padding: 0 13.5px; height: 40px;border-radius: 20px; border:1px solid currentColor;}
                    &:before {content:''; width:1px; height: 41px; background: #fff;margin-right: 16px;}
                }
                ul {} 
                &:hover > a {color:#A7039F; border-color:#a7039f;} 
            }
            ul { transition: all 0.3s ease; list-style: none; display: block;
                li {margin:0;
                    a {padding: 1px 15px; border:none; height: auto;font-weight: 400;font-size: 10px;color: #4B4B4B;
                        &:hover {color:#A7039F;}
                    } 
                }
            }
        } 
    }  
    
    &_search {position: relative; width: 387px;margin-top: 3px;
        &_icon {width: 24px;height: 24px; 
            svg {width:100%;height: 100%; vertical-align: top;}
        }
        &_opener {display: none;}
        &_drop {border: 1px solid currentColor;height: 41px; position: relative; border-radius: 20px;
            .header_search_icon {top:7px;left:11px; position: absolute; pointer-events: none;}
        }
        .form-control {padding: 2px 0 0px 47px; border: none; background: transparent;height: 38px;font-weight: 400;font-size: 16px; color:currentColor;}
    }
}   

.header_opener {position: relative; margin: -3px -4px 0 15px; display: none; z-index:50; height: 31px; width: 31px; justify-content: center;align-items: center;
    span {backface-visibility: hidden;position: relative; background:#fff; transition:all 0.3s ease-out;width:15px; height:2px; display:block; margin:0px 7px 0px 0px;}   
    span:before,  span:after{ transition: all 0.3s linear;backface-visibility: hidden;  content:''; background:#fff; height:2px; display:block; width:21px; position:absolute;}
    span:before {top: 8px; left: 0%;}       
    span:after {bottom: 8px; left: 0;}       
    &.active {   
        span {background: transparent;}        
        span:before {top:0; transform: rotate(45deg);}   
        span:after  {bottom:0px; transform: rotate(-45deg);}
    }
    b {position: absolute;opacity: 0; font-size: 15px; top:5px;right:32px;font-weight: 400; visibility: hidden;transition: all 0.4s ease; color:#000;text-transform: uppercase;}       
} 
 
.whitehead {
    .header {background: #fff; color:#4B4B4B;}
    .header_logo a {color:#000;}
    .header_opener {
        span {background:#000;
            &:before, &:after {background:#000;}
        }
        &.active {   
            span {background: transparent;}     
        }
    }
}


/* */
@media screen and (min-width: 1280px) { 
    .header_nav ul li {
        ul {width:182px; padding: 10px 0; border-top:6px solid #A7039F; margin-top:-6px; visibility: hidden;opacity: 0;  position: absolute;left:0;top:100%;background: #fff; }
        &:hover, &.hover {
            ul {visibility: visible;opacity: 1;}
        }
    }
}
@media screen and (max-width: 1279px) { 
    .noscroll-tablet {
        body {overflow: hidden;} 
        .toptxt {display: none;}
    } 
    .header_logo {margin-bottom: -5px;} 
    .header_cols {position:relative; height: 72px;}
    .header.open { 
        .header_nav {transform: translateX(0%);}
        .header_cols:before {opacity: 1;visibility: visible;transform: translateX(0%);}
        .header_logo a {color:#000;} 
        .header_opener {
            span {background:#000;
                &:before, &:after {background:#000;}
            }
            &.active {   
                span {background: transparent;}      
            }
            b {visibility: visible; opacity: 1;}
        }
    }
    .header { overflow: hidden;}
    .header .wrapper {position: static;}  
    .header_opener {display: flex;} 
    .header_cols:before {opacity: 0;visibility: hidden; /*transition: all 0.4s ease;*/transform: translateX(100%);height: 72px; content:'';z-index: 5;position: absolute;top:0;left:0;right:0;background: #fff;}
    .header_nav {margin:0; transform: translateX(100%);display: flex; flex-direction: column;justify-content: space-between; padding: 72px 30px 30px;position: fixed;background: #fff; top:0;height: 100%;overflow: auto;z-index: 1;width:100%;right:0%;/*transition: all 0.4s ease;*/ text-align: center;}
    .header_nav ul {display: block;    
        li {display: block; line-height: 48px;
            a {font-size: 20px; line-height: 48px; height: auto;border-bottom: 1px solid rgba(0, 0, 0, 0.37);padding: 0; color: rgb(174, 11, 114);}
            ul {padding: 0 0 5px 20px;
                li { 
                    a {border:none; font-size: 20px; color:#000; padding: 0;}
                }
            } 
        } 
    }
    .header_nav ul li.li-cats a {border:none; height: auto;line-height: 48px;padding: 0;}
    .header_nav ul li.li-cats:before {display: none;}
    .header_search {margin-top:0;}
    
}
@media screen and (max-width: 1023px) { 
    /*.toptxt + .main-wrapper .header_nav {padding-top: 120px;} */
    .header_logo a img { max-height: 32px;}
}
@media screen and (max-width: 767px) { 
    .header_opener { margin: -3px -4px 0 5px;}
    .header_search {position: relative; width:41px; margin-top: -2px;
        &_opener {display: flex; width:41px;height: 41px;display: flex;align-items: center; justify-content: center;}
        &_drop { width: 387px; max-width: calc(100vw - 81px); position: absolute; top:0; right:5px; display: none;z-index: 10;}
        &.open {z-index: 20;
            .header_search_opener {visibility: hidden;}
            .header_search_drop {display: block;} 
        }
        .form-control { padding: 2px 0 0px 42px; font-size: 14px;height: 39px;}
    }
    .header_logo { transition: all 0.3s linear;}
    .opensearch {
        .header_logo {visibility: hidden; opacity: 0;}
    }
    
}

@media screen and (max-width: 399px) {  
    .header_nav {padding-left: 20px;padding-right: 20px;padding-bottom: 20px;}
}