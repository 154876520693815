/* reset */
$min-width: 320px;    
$max-width: 1318px;     
$base-bg: #DBDBDB;   
$base-font-size: 18px; 
$base-line-height: 1.61; 
$base-font-family: "aktiv-grotesk", sans-serif;  
$link-color: #AE0B72;
$default-transition:all 0.3s linear;
 
/* main variables */

//text, titles 
$text-color: #030405;
$title-color: #030405;

$font-size-h1: 27px; 
$line-height-h1: 1.5;      
$font-size-h2: 34px;
$line-height-h2: 1.46;    
$font-size-h3: 28px;
$line-height-h3: 1.2;   
$font-size-h4: 20px;
$line-height-h4: 1.25; 




