/* checkbox */
$checkbox-bg: #F6F6F6;
$checkbox-border-color: #C3C3C3;
$checkbox-border-width: 1px;
$checkbox-border-radius: 3px;
$checkbox-shadow: 0 0 1px 0px rgba(0,0,0,0.05);

$checkbox-checked-bg: #666;
$checkbox-checked-shadow: 0 0 1px 0px rgba(0,0,0,0.05);

$checkbox-disabled-opacity: 0.5;

/* radio */
$radio-bg: #fff;
$radio-border-color: #C3C3C3;
$radio-border-width: 1px;
$radio-border-radius: 50%;
$radio-shadow: 0 0 0 0px rgba(0,0,0,0);

$radio-checked-bg: #ffbb00;
$radio-checked-shadow: 0 0 1px 0px rgba(0,0,0,0.05);

$radio-disabled-opacity: 0.5;

/* select */
$select-bg: #fff;
$select-border-color: #C8E6F5;
$select-border-width: 1px;  
$select-border-radius: 12px;
$select-height: 58px;
$select-padding:18px 35px 18px 18px;    
$select-line-height: 20px;
$select-color: #34373E;

$trigger-arrow-bg:#fff;
$trigger-border-width:0px;
$trigger-arrow-color:#b3b5bd;
   
$dropdown-bg:#FFF;
$dropdown-border-width:1px;
$dropdown-border-color:#C8E6F5; 
$dropdown-border-radius: 0 0 12px 12px; 
$dropdown-shadow: none; 
$dropdown-item-padding: 7px 19px 7px 19px;

$dropdown-item-line-height:$select-line-height;
$dropdown-item-active-bg:#f2f5f7;
$dropdown-item-disbled-color:#ccc;
$dropdown-item-disbled-bg:none;


.jq-checkbox {
	width: 16px;
	    display: inline-block;
	height: 16px;
	border-radius: 4px;
	background: #fff;
	vertical-align: middle;
	cursor: pointer;
	border:2px solid #cccccc;
	margin-right: 11px;
	top: -2px;

}
.jq-checkbox.checked{border-color: #3399cc;}
.jq-checkbox.checked .jq-checkbox__div {
	width: 8px;
	height: 8px;
	margin: 2px 0 0 2px;
	border-radius: 2px;
	background:#3399cc;
	
}
.jq-checkbox.focused {}
.jq-checkbox.disabled {
	opacity: $checkbox-disabled-opacity;	
}


.jq-radio {
	width: 16px;
	    display: inline-block;
	height: 16px;
	border-radius: 50%;
	background: #fff;
	vertical-align: middle;
	cursor: pointer;
	border:2px solid #cccccc;
	margin-right: 11px;
	top: -2px;
}
.jq-radio.checked{	border-color: #3399cc;}
.jq-radio.checked .jq-radio__div {
	width: 8px;
	height: 8px;
	margin: 2px 0 0 2px;
	border-radius: 50%;
	background:#3399cc;
}
.jq-radio.disabled {	opacity: $radio-disabled-opacity;	}

/* */
.jq-file{position:relative;width:100%;display:inline-block;overflow:hidden;background: #FFFFFF; border-radius: 12px;   height: 58px; border: 1px solid #C8E6F5;    padding: 2px;}
.jq-file input{position:absolute;top:0;right:0;height:auto;margin:0;padding:0;opacity:0;font-size:100px;cursor:pointer;line-height:1em;}
.jq-file__name{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;box-sizing:border-box;width:100%;
transition: all 0.3s linear; position: relative; padding: 14px 120px 14px 18px; text-overflow: ellipsis;overflow: hidden;display:block;width:100%;  
height:54px; font-size:14px; color: #BEBEBE; line-height:1.7; font-family: $base-font-family;   
background:#fff; border-radius: 10px;}
.jq-file__browse{position:absolute;top:2px;right:2px;transition: all 0.3s linear;display: inline-flex; align-items:center;	justify-content: center; vertical-align: middle;	height: 52px; padding: 5px 5px; font-family: "Sora", sans-serif; text-align: center; cursor: pointer;backface-visibility: hidden; -moz-osx-font-smoothing: grayscale; 
color: #fff;font-size: 16px; line-height: 1.2; font-weight: 600;width:108px; box-shadow: none; background: #68C9FA;border: 1px solid transparent;border-radius: 10px;}
.jq-file:hover .jq-file__browse{    color: #fff;    background: #38ADE8;} 
.jq-file:active .jq-file__browse{    color: #fff;    background: #0C89C8;}
.jq-file.focused .jq-file__browse{    color: #fff;    background: #0C89C8;}
.jq-file.disabled .jq-file__browse{color:#fff; background: #D7D7D7;}
.jq-file.changed .jq-file__name {color:#34373E;}  
.jq-file.changed {border-color: #80C3E4;}  


/* */
.jq-selectbox {	vertical-align: middle;	cursor: pointer;	width:100%;} 
.jq-selectbox .placeholder {color: $select-color;} 
.jq-selectbox__select {
	height: $select-height;
	padding: $select-padding;
	border: $select-border-width solid $select-border-color;
	border-radius: $select-border-radius;  
	background: $select-bg;
	line-height: $select-line-height;
	color: $select-color; font-size:14px;
	z-index: 1;
	font-family:$base-font-family;  
} 
.jq-selectbox.error .jq-selectbox__select, .jq-selectbox.error.placeholder .jq-selectbox__select { border-color: #FFB1B1; color: #F77A68;}

.jq-selectbox.changed .jq-selectbox__select {border-color: #80C3E4;} 
.jq-selectbox.disabled .jq-selectbox__select {
	border-color: #CCC; 
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
} 
.jq-selectbox__select-text {
	display: block;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.jq-selectbox__trigger {
	position: absolute;   
	top: 0px;
	right: 0;
}
.jq-selectbox__trigger-arrow {
	position: absolute;
	top: 17px;      
	right: 19px; 
	width: 24px;    height: 24px;    background: url(../svg/arrow_down.svg) no-repeat center; background-size: 100%;
}
.jq-selectbox.opened .jq-selectbox__select {border-radius: 12px 12px 0 0;}
.jq-selectbox.opened .jq-selectbox__trigger-arrow { background-image: url(../svg/arrow_down-rev.svg); top: 18px;   	}
.jq-selectbox.disabled .jq-selectbox__trigger-arrow {}
.jq-selectbox__dropdown {  overflow: hidden; 
	top: 100% !important;padding: 16px 0;
	width: 100%;
	box-sizing: border-box;   
	line-height: 20px;left:0;font-size:14px;
	border: $dropdown-border-width solid $dropdown-border-color;
	background: $dropdown-bg; margin-top: -3px;    z-index: 1;
	border-radius: $dropdown-border-radius; 
}
.jq-selectbox__search {   
	margin: 5px;
}
.jq-selectbox__search input {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 5px 27px 6px 8px;
	outline: none;
	border: 1px solid #CCC;
	border-radius: 3px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==) no-repeat 100% 50%;
	box-shadow: inset 1px 1px #F1F1F1;
	color: #333;
	-webkit-appearance: textfield;
}
.jq-selectbox__search input::-webkit-search-cancel-button,
.jq-selectbox__search input::-webkit-search-decoration {
	-webkit-appearance: none;
}
.jq-selectbox__not-found {
	margin: 5px;   
	padding: 5px 8px 6px;
	background: #F0F0F0;
	font-size: 13px;
}
.jq-selectbox ul {
	margin: 0;
	padding: 0;
}
.jq-selectbox li {
	min-height: 18px;
	padding:$dropdown-item-padding;
	line-height: $dropdown-item-line-height;  
}
.jq-selectbox li:hover {  
	color: #38ADE8;          
}
.jq-selectbox li.selected, .jq-selectbox li.sel {
	color:  #38ADE8;
}
.jq-selectbox.placeholder .jq-selectbox__select, .jq-selectbox li.placeholder  {color: #C2C2C2;}
.jq-selectbox li.placeholder {display: none !important;}
.jq-selectbox li.disabled {
	color: $dropdown-item-disbled-color;  
	background: $dropdown-item-disbled-bg;
}
.jq-selectbox li.disabled:hover {  
	background: none;
}
.jq-selectbox li.optgroup {
	font-weight: bold;  
}
.jq-selectbox li.optgroup:hover {
	background: none;
	color: #231F20;
	cursor: default;
}
.jq-selectbox li.option {
	padding-left: 25px;
}




