@font-face {
    font-family: 'Rockwell';
    src: url('../fonts/Rockwell.eot');
    src: url('../fonts/Rockwell.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rockwell.woff2') format('woff2'),
        url('../fonts/Rockwell.woff') format('woff'),
        url('../fonts/Rockwell.ttf') format('truetype'),
        url('../fonts/Rockwell.svg#Rockwell') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rockwell';
    src: url('../fonts/Rockwell-Bold.eot');
    src: url('../fonts/Rockwell-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rockwell-Bold.woff2') format('woff2'),
        url('../fonts/Rockwell-Bold.woff') format('woff'),
        url('../fonts/Rockwell-Bold.ttf') format('truetype'),
        url('../fonts/Rockwell-Bold.svg#Rockwell-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap; 
}

