:root {
  --font-family: "aktiv-grotesk", sans-serif;
  --second-family: "Inter", sans-serif;
  --third-family: "Helvetica Neue", sans-serif;
}


//main styles
.main-wrapper {
    padding: 0 0 0 0;
	min-width: $min-width;
    width: 100%;
	position: relative;
	overflow: hidden;
	min-height:100%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex; 
	display: flex;
	flex-direction:column;
    -webkit-flex-direction:column;
    -ms-flex-direction: column;
	justify-content:flex-start;	
    -webkit-justify-content:flex-start;	
    -ms-flex-pack: start;
    transition: all 0.2s linear;
    
}  
.pageback {width:100%; flex-grow: 1;
	background: #fff; position: relative;
    margin:0 auto; 
    max-width: $max-width; 
    display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox; 
	display: -webkit-flex; 
	display: flex;
	flex-direction:column;
    -webkit-flex-direction:column;
    -ms-flex-direction: column;
	justify-content:flex-start;	
    -webkit-justify-content:flex-start;	
    -ms-flex-pack: start;

  section:only-child {flex-grow: 1;}
}
 
.wrapper {
	min-width: 250px;
	max-width: $max-width;
	padding: 0 30px; padding: 0 4.4%;  
	margin: 0 auto;      
	position: relative;    
	z-index: 2; 
	width: 100%; 
}  

ol {list-style-position: inside;}
/* titles */    
p{	
	padding: 0rem 0 20px 0;
}
h1, .h1{
	@include font($font-size-h1,$line-height-h1,inherit);
	padding: 0rem 0 25px 0px;
	font-weight: 400;
	
}
h2, .h2{
	@include font($font-size-h2,$line-height-h2,inherit);
	padding: 0rem 0 20px 0;       
	font-weight: 300; 
}    
h3, .h3{
	@include font($font-size-h3,$line-height-h3,inherit); 
	padding: 0rem 0 23px 0;      
	font-weight: 500;
}
h4, .h4{
	@include font($font-size-h4,$line-height-h4,inherit);
	padding: 0rem 0 20px 0;     
	font-weight: normal;
}
h5, .h5 {
	color: $title-color;
	font-weight: 600;
	padding: 0rem 0 10px 0;     
	font-size: 16px;
	line-height: 1.3;
}
h6, .h6 {
	color: $title-color;
	font-weight: normal;
	font-size: 14px;
	line-height: 1.3;
}

.aligncenter {display: block;margin:0 auto;}
.alignleft {float: left;}
.alignright {float: right;}

/* text position */
.text-left{text-align: left!important}
.text-center{text-align: center!important}   
.text-right{text-align: right!important} 
.nowrap{white-space: nowrap!important;}
   
/* loader */
/*.loaded .main-wrapper {visibility:hidden; opacity: 0;}      
.icon-load{position:fixed;width:100%;height:100%; top:0;left:0;display:none; background-color:#fff;z-index: 200;}
.loaded .icon-load{display:block;}
*/
/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content{	
	min-width: $min-width;
	text-align: left;
    width: 100%;  
	order: 2;  
    -webkit-order: 2;
	flex-grow: 1;
    -webkit-flex-grow: 1;   
display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox; 
	display: -webkit-flex; 
	display: flex;
	flex-direction:column;
    -webkit-flex-direction:column;
    -ms-flex-direction: column;
	justify-content:flex-start;	
    -webkit-justify-content:flex-start;	
    -ms-flex-pack: start;
}         

/* */
.btn {gap:4px; transition: all 0.3s linear;display: inline-flex; align-items:center;	justify-content: center; position: relative;vertical-align: middle; 
	height: 40px;padding: 5px 20px; font-family: var(--second-family); text-align: center; cursor: pointer;backface-visibility: hidden; -moz-osx-font-smoothing: grayscale; 
color: #fff;font-size: 16px; line-height: 1.2; font-weight: 700; box-shadow: none; 
background: #4a007d;background: linear-gradient(90deg, #4a007d 0%, #de106d 100%);
border: 0px solid transparent;border-radius: 7px;
	&:hover {color:#fff; background: linear-gradient(90.00deg, rgb(49, 0, 83) 0%,rgb(165, 13, 81) 100%);}   
	&:hover:active, &:hover:focus { background-image: linear-gradient(106deg, #3a0062 -8%, #60012c 104%); box-shadow: none;}
  &:disabled { border: solid 1px #b7b7b7;cursor: default;background: transparent;  color: #b7b7b7; box-shadow: none;}

	.icon {width:12px;height:10px;}

} 
.btn-bord { color: rgb(161, 7, 154); border: 1px solid rgb(161, 7, 154);border-radius: 7px;background: #fff;
    &:hover {color: rgb(161, 7, 154); border: 1px solid rgb(161, 7, 154);background: rgba(161, 7, 154, 0.05);}
    &:hover:focus, &:hover:active {color: rgb(161, 7, 154); border: 1px solid rgb(161, 7, 154);background: rgba(161, 7, 154, 0.05);}
} 


.form_row {margin-bottom: 16px;}
.form_label {display: block; font-weight: 600;font-size: 16px;line-height: 1.3;padding: 0rem 0 16px 0; color: #152A54;}
.form_btn {text-align: right;}

label.error {font-size: 1.4rem;position: absolute; color: #B50000;top:100%; left:2rem;right:0;line-height: 1.6rem; font-weight: 300;}
 
.form-control{ transition: all 0.3s linear; position: relative; padding: 5px 20px; text-overflow: ellipsis;overflow: hidden;display:block;width:100%;  
height:58px; font-size:14px; color:#34373E; line-height:1.7; font-family: $base-font-family;   
background:transparent; border: 1px solid #C8E6F5; border-radius: 12px;
	&.filled {border-color: #80C3E4;}
}   
  
.form-control:focus, .form-control:hover { } 
.form-control.error {border-color:#FFB1B1;}
textarea.form-control {height: 294px;border-radius: 20px; resize:none;}         
.form-control::-webkit-input-placeholder {color: currentColor; opacity: 1;}
.form-control:-moz-placeholder {color: currentColor; opacity: 1;}      
.form-control::-moz-placeholder {color: currentColor; opacity: 1;} 
.form-control:-ms-input-placeholder {color: currentColor; opacity: 1;}   

.checks {
	&_item { color: #222222;padding: 0 0 20px;color: #6F737B;
		label {display: inline-flex; cursor: pointer; position: relative;}
		input[type="checkbox"] {position: absolute;}
		&_txt {padding-left:48px; position:relative;
			&:before {width: 30px;height: 30px; border: 2px solid #CCEEFC;border-radius:6px; content:'';position: absolute;  left:0;top:50%; margin-top:-15px;}
			b {color: #152A54;}
			a {text-decoration: underline;font-weight: bold;
				&:hover {text-decoration: none;}
			}
		} 
		input:checked + .checks_item_txt {}
		input:checked + .checks_item_txt:before {background:url(../svg/check.svg) no-repeat center #CCEEFC; background-size: 20px;}
	}
}

/*---------------------------------------*/ 
.bg-grey {background-color: #F5F5F5;}
.icon { fill: currentColor; vertical-align: middle; max-width: 100%; max-height: 100%; display: inline-block; }
.icon-str { fill: none; stroke: currentColor; vertical-align: middle; max-width: 100%; max-height: 100%; display: inline-block; }
 
/*-----------------------------*/ 
.section-welcome {position: relative; } 
.welcome {padding:107px 0 21px;position: relative; color:#fff; background: #000;
	&_bg {position: absolute; top:0;left:0;z-index: 0;width:100%;height: 100%;
		img {width:100%;height: 100%; object-fit: cover;}
		&:before {content:'';background: rgba(0, 0, 0, 0.67);position: absolute; top:0;left:0;z-index: 0;width:100%;height: 100%;z-index: 1;}  
	}
	&_cont {position: relative;z-index: 1;}
	&_txt {line-height: 23px; letter-spacing: -0.27px;  max-width: 975px;
		p {padding-bottom: 23px;}
		p:last-child {padding-bottom: 0px;}
		a {color: currentColor; text-decoration: underline; 
			&:hover {color: rgb(222, 16, 109);}
		}
	}
	h1, .h1 {font-weight: 300; font-size: 26px;letter-spacing: -0.35px;padding: 0rem 0 12px 0px;
		strong {font-weight: 500; letter-spacing: -0.5px;} 
	}
}

/* */
.section-columns {}
.columns {display: flex; 
	&_center {flex-grow:1; padding: 39px 3.1% 0px 4.3%;}
	&_aside {padding: 47px 4.2% 47px 3.8%; background: #1c3345; min-width: 367px;width: 367px; color:#fff;
		h3 {padding-bottom: 7px;font-size: 25px;font-weight: 400;line-height: 30px;} 
	} 
} 

.cards {
	&_item {transition: all 0.3s linear; position: relative; display: flex; border: 1px solid rgba(112, 112, 112,0.79);border-radius: 4px;box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1738);
		&:not(:last-child) {margin-bottom: 20px;}
		&_left {padding: 25px; width:211px;min-width: 211px; background: #F2F2F2;border-radius: 4px 0 0 4px; overflow: hidden;}
		&_logo { display: flex; align-items: center;height: 218px; justify-content: center;
			img {max-height: 100%;} 
		}
		&_cont {flex-grow: 1; position: relative;z-index: 1; padding: 34px 44px 23px 27px; display: flex; flex-wrap:wrap;}
		&_cntr {width:calc(100% - 145px); padding-right: 35px;padding-top: 4px;}
		&_right { width:145px;min-width: 145px; text-align: center;}
		&_title {    margin-bottom: 8px;color: rgb(0, 0, 0);font-size: 15.71px;font-weight: 500;line-height: 20.33px;} 
		&_txt {margin-bottom: 1px;letter-spacing: -0.18px;color: rgb(51, 51, 51);font-size: 12px;font-weight: 500; line-height: 17px;
			p {}
			ul {
				li {position: relative; list-style: none; padding: 0 0 8px 17px;
					&:before {left:-1px;top:2px; content:'';position: absolute;width:14px;height: 13px; background: url(../svg/check.svg) no-repeat center; background-size: contain;}
					strong {letter-spacing: 0px; font-size: 13.86px; font-weight: 700;}
				} 
			}
		}
		.stars {height: 15px; width:100px;margin: 0 0 13px -2px;} 
		&_review {font-size: 13.86px;
			a {color: rgb(222, 16, 109);line-height: 25px;display: inline-block; font-weight: 700; text-decoration-line: underline;
				&:hover {color: rgb(134, 9, 134);}
			}
		}
		&_btn {margin: 19px 0 5px; 
			.btn {width:100%; height: 35px;} 
		} 
		.rating_value_num {width: 43.36px; height: 31px;}
		&_price {
			a {color: rgb(19, 100, 191);font-family: "Inter",sans-serif; display: inline-flex; align-items: center;font-size: 13.3px;font-weight: 500; 
				svg {margin-left:8px; width:11px;height: 10px;}
				&:hover {color: rgb(0, 0, 0);}
			}
		} 
		&_label {padding-right: 17px; width:195px;height: 44px; text-align: center; line-height: 33px;overflow: hidden; color:#fff; top:30px;left:-13px;font-family: "Inter",sans-serif; font-weight: 500;background: url(../svg/label1.svg) no-repeat 0 0; background-size: contain; font-size: 13px; letter-spacing: -0.03em;position: absolute;z-index: 2;
			&1 {background: url(../svg/label1.svg) no-repeat 0 0; background-size: contain;}
			&2 {background: url(../svg/label2.svg) no-repeat 0 0; background-size: contain;}
			&3 {background: url(../svg/label3.svg) no-repeat 0 0; background-size: contain;}
		} 
		&_special {flex-grow:1; margin:30px 0 -5px -199px; display: flex; text-align: center;border: 2px dashed rgb(59, 178, 115); background: rgb(189, 240, 206);
			&_left {letter-spacing: 0.7px;justify-content: center; gap:7px; display: flex; align-items: center; flex-grow:1;font-family: "Rockwell", serif; font-size: 18px;font-weight: 400; color:#000;
				strong {color:rgb(16, 80, 46); font-weight: 700;}
			}
			&_right {transition: all 0.3s linear; justify-content: center; color:#fff; padding: 0 19px; font-size: 18px;gap:13px; display: flex; align-items: center; font-weight: 700;line-height: 26px;height: 36px;background: rgb(59, 178, 115);
				svg {width:14px;height:13px;}
			}
			&:hover {
				.cards_item_special_right {background: #349B65;}
			}
		}
		&:hover {border: 1px solid rgba(134, 9, 134,0.79); background: rgba(246, 246, 246,0.79);}
	}
}

.stars {height: 20px; width:133px; background: url(../img/starsbg.png) no-repeat 0 0; background-size: auto 100%;
	span {display: block;height: 100%;background: url(../img/stars.png) no-repeat 0 0; background-size: auto 100%;}
}


.sideblock:not(:last-child) {margin-bottom: 29px;}
.faq {    margin-left: 4px;
	&_item {font-size: 18px;font-weight: 300;line-height: 29px;  letter-spacing: 0.2px;
		&_q {cursor: pointer; position: relative; padding: 0 0 0 13px;
			&:before {content:''; position: absolute; top:9px;width:5px;height:8px; left:2px; background: url(../svg/icons/corn.svg) no-repeat center; transition: all 0.3s linear;}
			&:hover {text-decoration: underline;}
			p {padding: 0;} 
		} 
		&_ans {display: none; padding: 0 0 10px 23px;   
			p {padding: 0;}
		}
		&.open {
			.faq_item_q {text-decoration: underline;} 
			.faq_item_q:before {transform: rotate(90deg);}
		}
	}
}

.posts {
	&_item {margin-bottom: 14px;margin-top: 8px;
		&_title {font-weight: 500;font-size: 20px;line-height: 125%;letter-spacing: 0.16px; margin-bottom: 8px;
			a {color:#00DEFF;text-decoration: underline; text-decoration-skip-ink: none;
				&:hover {color:#009CB3;}
			}
			p {padding: 0;}
		}
		&_desc {font-size: 16px;line-height: 181%;letter-spacing: 0.16px;margin-bottom: 6px;
			p {padding: 0;} 
		}
		&_link {
			a {color:#00DEFF;text-decoration: underline;font-weight: 500;font-size: 16px;line-height: 156%;letter-spacing: 0.01em;
				&:hover {color:#009CB3;}
			}
		}
	}
}

/* */
.section-compare { padding: 56px 0 30px; overflow: hidden;
	.wrapper:before {margin-bottom: 26px; content:'';display: block;border-radius: 6px;width: 24.1%;height: 12px;background: rgb(167, 3, 159);}
	h2 {text-align: center; font-size: 38px;padding: 0rem 0 19px 0;}
	.scroll-wrapper {margin-right: -30px;}
}

.table-scroll {font-size: 14px;	position:relative; margin: 0 0 0 0; /* overflow:hidden;border: 1px solid rgb(112, 112, 112); border-radius: 4px;*/
	.fixed-side {line-height: 20px; padding: 0px 16px 0px 16px; width: auto; min-width: 166px; font-weight: 400;font-size: 16px;border-bottom: 1px solid rgba(112, 112, 112,0.34);}
	td, th {vertical-align: middle; height: 61px;} 
	td:not(.fixed-side) {min-width: 207px;}
	td {text-align: center; line-height: 17px;} 
	a {font-weight: 700;
		.icon {width:11px;height:10px; margin-left: 3px;} 
		&:hover {color:#860986;} 
	} 
	.btn { gap: 1px; height:41px; margin: 16px auto 14px; width:156px;
		&:hover {color:#fff;}
	}
	tfoot tr:last-child th, tfoot tr:last-child td {border:none;}
}
.table-img {width: 132px;height: 67px;margin:0 auto;margin: 13px auto 11px;
	img {width:100%;height: 100%;object-fit: cover;}
}
.table-wrap {display: flex;	/*width:100%;	overflow:auto; border: 1px solid rgb(112, 112, 112); border-radius: 4px;*/
	&:after {content:'';display: block; min-width: 30px; width:30px;}
} 
.table-scroll {
	table { border: 1px solid rgb(112, 112, 112); border-radius: 4px; 	/*width:100%;*/	margin:auto; border-spacing:0;}
}
.table-scroll th, .table-scroll td {
	padding:5px 10px;
	border-bottom: 1px solid rgba(112, 112, 112,0.34);
	background:#fff; width:207px; 
}
.table-scroll th:nth-child(even), .table-scroll td:nth-child(even) {background: rgba(183, 183, 183,0.2);}
.table-scroll tfoot {
	th, td {border:none;} 
}
.table-scroll {
	.clone {border-color: transparent transparent transparent rgb(112, 112, 112);	position:absolute;	top:1px;border-top:none;border-bottom:none;	left:0;	pointer-events:none;}
	.clone th, .clone td {	visibility:hidden}
	.clone td {border-color: transparent; }
	.clone tbody th {	visibility:visible;}
	.clone .fixed-side { background:#fff;	visibility:visible;}
}

/* */
.section-textblock { padding: 31px 0 19px;}
.textblock-sm {font-size: 16px;line-height: 29px;letter-spacing: 0.01px; 
	p {padding-bottom: 29px;} 
	ul, ol {padding: 0 0 29px 20px;}
}
.textblock {line-height: 29px; letter-spacing: 0.02px; 
	h2 {color: rgb(174, 11, 114); padding-bottom: 11px; line-height: 1.38; font-weight: 500; letter-spacing: -0.17px;}
	h3 {color: rgb(174, 11, 114); padding-bottom: 21px; line-height: 1.35; letter-spacing: -0.1px;}
	h4 {max-width:100%; font-weight: 300; width: fit-content; padding-bottom: 5px; margin-bottom: 15px; font-size: 24px; border-bottom: 4px solid #AE0B72;}
	p {padding-bottom: 29px;} 
	p:last-child {padding-bottom: 25px;} 
	p + h2 { margin-top: -4px;}
	p + h3 { margin-top: -2px;}
	p + h4 { margin-top: -14px;} 
	a {font-weight: 400; text-decoration: underline; color: currentColor;
		&:hover {color:#860986;}
	}
	ul, ol {padding: 0 0 29px 20px;}
	img {margin: 0px 0 15px;}
	h3 + img { margin-top: -4px;}  
}
.textblock-bot {line-height: 29px; 
	p {padding: 0rem 0 8px 0;} 
	p:last-child {padding-bottom: 0px;} 
	a {font-weight: 400; text-decoration: underline;}
}

/* */
.section-suggest { padding: 30px 0;
	h2 {text-align: center; font-size: 38px;}
} 
.suggest {
	&_slider {margin:-6px auto -1px; max-width: 1090px;} 
	&_item { overflow: hidden; margin:4px 0; border: 1px solid rgba(112, 112, 112,0.79); border-radius: 9px; font-size: 14px; box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.158);
		&_logo {margin:0 20px 9px; height: 52px;display: flex;align-items: center;justify-content: center; text-align: center;border-bottom: 1px dashed rgb(112, 112, 112);
			img {max-height:100%;} 
		}
		&_top {margin:0 0px 8px; display: flex; align-items: center;}
		&_rating {width:50%; text-align: center;display: flex; align-items: center; justify-content: center;flex-direction: column;}
		.stars {height: 11px; width:73px; margin-top: 4px;}
		&_review {width:50%; height: 40px;text-align: center;display: flex;align-items: center;justify-content: center; border-left: 1px solid rgb(112, 112, 112);
			a {color: rgb(174, 11, 114); font-size: 15px; font-weight: 500;line-height: 25.65px;
				&:hover {color: #310053;}
			}
		}
		&_img {position: relative; overflow: hidden;
			img {width: 100%;height: 100%;object-fit: cover;}
		}
		&_cont {padding: 9px 19px 7px;} 
		&_txt {line-height: 157%; margin-bottom: 7px;
			p {padding: 0;} 
		}
		&_btn { margin-bottom: 3px; 
			.btn {width:100%;}
		}
		&_link {text-align: center;
			a {font-weight: 700; font-size: 13px;color: #707070;
				&:hover {color: #310053;} 
			}
		}
	}
}
.rating {
	&_value {justify-content: center; gap:10px; line-height: 27px;color: rgb(19, 100, 191);display: flex;align-items: center; font-size: 15px;font-weight: 700;
		&_num {border-radius: 3.65px;display: flex;align-items: center;justify-content: center; width: 40.99px;height: 32.28px;background: rgb(19, 100, 191);color: rgb(255, 255, 255);font-family: "Inter",sans-serif;font-size: 21px;font-weight: 400;line-height: 25px;letter-spacing: -5%;}
		&_txt {}
	}
}

/* */ 
.section-inner {padding: 103px 0 50px;}
.breadcrumbs {line-height: 25px; margin-bottom: 15px; letter-spacing: 0.1px; color:#6A6A6B; font-size: 14px;font-weight: 500;
	a {color:#6A6A6B;
		&:hover {color:#860986;} 
	}
	span {}
} 
.breadcrumbs + .article {margin-top:32px;}
.article {
	h1 {color:#AE0B72;font-weight: 400; font-size: 28px; line-height: 130%;    padding: 0rem 0 30px 0px;}
	h2 {color:#AE0B72;font-weight: 400; font-size: 28px; line-height: 139%; padding: 0rem 0 7px 0;}
	h3 {color:#AE0B72;font-weight: 400; font-size: 24px; line-height: 139%;}
	h4 {color:#AE0B72; font-size: 20px; line-height: 139%;}
	p {padding-bottom: 29px;}
	p + h2 {margin-top: -7px;}
	a {text-decoration: underline; color: currentColor;
		&:hover {color:#860986;} 
	}
	ul, ol {padding: 0 0 29px 17px;}
	p:last-child, ul:last-child, ol:last-child {padding-bottom: 0px;}
	p + ul, p + ol {margin-top: -29px;}
	.alignleft {float:left;margin-right: 20px;}
	.alignright {float:right;margin-left:20px;}
	.aligncenter {margin-left:auto;margin-eight:auto;display: block;}
	.fullwidth {width:100%;} 
}

.reviews {
	&:not(:first-child) {margin-top:21px;} 
	&_item {padding: 16px 31px 13px; transition: all 0.3s linear;margin-bottom: 16px; display: flex;background: rgb(255, 255, 255);border: 1px solid rgba(112, 112, 112, 0.79);border-radius: 4px;box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1738);
		&:last-child { margin-bottom: 7px;}
		&_img {margin: 8px 0 11px; width: 201px;height: 150px; min-width: 201px; margin-right: 36px;
			img {width:100%;height: 100%;object-fit: cover;}
		}
		&_cont { max-width: 842px; position: relative; flex-grow:1; padding-right: 210px; display: flex; flex-direction: column;}
		&_title {max-width: 605px; margin-bottom: 7px; overflow: hidden;font-size: 28px;font-weight: 400; line-height: 1.38; white-space: nowrap; text-overflow: ellipsis;
			p {padding: 0;}
		}
		&_rating {position: absolute; padding: 0 0 16px; right:0;top:19px;width: 188px; text-align: center;border-bottom: 1px solid rgba(112, 112, 112,0.34);
			&_title { margin-bottom: 6px; color: rgb(134, 9, 134); font-weight: 700;}
			.stars {margin:0 auto;} 
		}
		&_txt {max-width: 605px; margin-bottom: 7px; display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;
			p {padding: 0;}  
		}
		&_bot {margin-top:auto;}
		&_link {
			a {text-decoration-line: underline;font-size: 16px;font-weight: 700;letter-spacing: -0.01px; font-family: "inter",sans-serif;
				&:hover {color:#860986;} 
			}
		}
		&_btn {position: absolute;right:0;top:106px;width: 188px; text-align: center;
			.btn {width: 148px; height: 40.75px;}
		}
		&:hover {background: rgba(246, 246, 246,0.79);}
	} 
}
 
.smtxt {margin-bottom: 32px; color: rgb(0, 0, 0);font-size: 14px;line-height: 20px; 
	p {padding: 0;}
	strong {font-weight: 500;} 
	a {color:currentColor; text-decoration: underline;
		&:hover {color:#860986;}
	}
} 

.win {width:706px;border-radius: 10px;padding: 31px 30px 29px; box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.22);background: rgb(255, 255, 255);
	&_txt {font-size: 12px;font-weight: 400;line-height: 23px;letter-spacing: -0.15px;
		p {padding: 0;}
		a {text-decoration: underline; color:rgb(0, 222, 255);
			&:hover {color:#860986;}
		}
	}
}
.description { 
	h1 {font-weight: 400; padding: 0rem 0 9px 0px; letter-spacing: 0.09px; text-transform: capitalize;}  
}

.toptxt { z-index: 200; position: relative; padding: 9px;text-align: center; background: rgba(242, 242, 242,0.73);font-size: 12px;font-weight: 300;line-height: 16px;letter-spacing: 0.4px;}
 
.pageerror_title {margin: -6px 0 3px;letter-spacing: -2px;font-size: 130px;font-weight: 500;line-height: 0.9;}
.pageerror_txt { margin-bottom: 5px; font-size: 26px;font-weight: 500;line-height: 1.48; letter-spacing: -0.43px;
	p {padding: 0;}
}

/* */
.section-pagehead {position: relative; } 
.pagehead {padding:57px 0 57px;position: relative; color:#fff; background: #000;margin-bottom: -18px;
	.wrapper {display: flex; justify-content: space-between; gap:30px; align-items: center;
		&:after {display: none;}
	}
	&_bg {position: absolute; top:0;left:0;z-index: 0;width:100%;height: 100%;
		img {width:100%;height: 100%; object-fit: cover;}
		&:before {content:'';background: rgba(0, 0, 0, 0.67);position: absolute; top:0;left:0;z-index: 0;width:100%;height: 100%;z-index: 1;}  
	}
	&_cont {position: relative;z-index: 1;}
	&_img {width: 337px;height: 204px;
		img {width:100%;height: 100%; object-fit: cover;}
	}
	&_title {font-weight: 300; font-size: 26px;letter-spacing: -0.35px;padding: 0rem 0 12px 0px;}
	&_txt {line-height: 23px; letter-spacing: -0.27px;  max-width: 975px;
		p {padding-bottom: 23px;}
		p:last-child {padding-bottom: 0px;}
		a {color: currentColor; text-decoration: underline; 
			&:hover {color: rgb(222, 16, 109);}
		}
	}
	h1, .h1 {font-weight: 300; font-size: 26px;letter-spacing: -0.35px;padding: 0rem 0 12px 0px;
		strong {font-weight: 500; letter-spacing: -0.5px;} 
	}
	.rating_value {display: block;}  
	.rating_value_num {width: 60px; height: 43px;font-size: 38px;letter-spacing: -2px; margin-bottom: 6px;}
	.rating_value_txt {letter-spacing: 0.1px;color:#fff; font-size: 14px;font-weight: 400;line-height: 22.33px;}
	&_rating {display: flex; gap:32px;    margin: 8px 0 5px;align-items: center;} 
	&_stars {
		p {font-size: 17px;font-weight: 400;line-height: 22.33px;padding: 6px 0 0;}
	}
	.stars {    margin-left: -2px;}
	&_btn {
		.btn {width: 301px;font-size: 19px;font-weight: 600;height: 45px;text-transform: uppercase; }
	}
}

/* */
.section-review {}
.how {    margin-bottom: 33px; border: 1px solid rgba(0, 0, 0, 0.37);padding: 16px 27px 14px; border-radius: 4px;
	&_title {color: rgb(174, 11, 114);font-size: 28px;font-weight: 400;line-height: 36px;}
}
.numbs {    margin: 0 -3px 0px; display: flex; justify-content: space-between; text-align: center;
	&_item { margin-top: 28px; padding: 0 5px;
		&_icon {display: flex;margin:0 auto 13px; height: 98px; align-items: center;justify-content: center;
			img {max-height: 100%;}
		}
		&_val {margin-bottom: 7px;color: rgb(134, 9, 134);font-size: 26px;font-weight: 700;line-height: 33px;}
		&_txt {color: rgb(134, 9, 134);font-size: 20px;font-weight: 400;line-height: 26px;}
	}
} 
.review { 
	.columns_center { padding: 17px 3.35% 0px 4.4%; 
		.sale {margin-left: -6.8%;}
	}
	&_data {margin:0 0 20px 20px; text-align: right;float:right; color: rgb(0, 0, 0);font-size: 14px;font-weight: 300;line-height: 19px;
		p {padding: 0;} 
	}
	&_aside {z-index: 2; width:368px; min-width: 368px; /*position: sticky;*/top:0; padding: 0;}
	&_disc {background: #F5F5F5; padding: 34px 32px 33px; margin-bottom: 32px;
		&_btn {margin-top:30px;text-align: center; 
			.btn {width:100%; height: 41px;}
		}
		.smtxt {margin-bottom: 30px;}
	}
	&_txt {
		h4 {font-size: 22px;}
	} 
	&_btn {text-align: center; margin:30px 0;
		.btn {min-width: 238px; height: 45px; max-width: 100%;}
	}
	&_nav {margin-top: -1px;letter-spacing: 0.1px; padding: 13px 0 14px; font-size: 14px;font-weight: 500;line-height: 25px;display: flex; justify-content: space-between;border-top: 1px solid rgba(0, 0, 0, 0.37);
		a {color: rgb(75, 75, 75);
			&:hover {font-weight: 700;color: rgb(3, 4, 5);}
		}
	}
	&_foot {margin-left: -6.8%; margin-right: calc(-368px - 5.2%); padding: 24px 400px 13px 6.5%; padding-right: calc(368px + 5.2%); border-top: 8px solid rgba(197, 197, 197, 0.55);}
	.article { margin-bottom: 24px;
		&:last-child {margin-bottom: 0;}
		p + h4 {margin-top: -7px;} 
		h3 {padding-bottom: 3px;}
		h4 {padding-bottom: 8px;}
		a {color:rgb(174, 11, 114);text-decoration: none;
			&:hover {text-decoration: underline;}
		}
	}

}
.review-date + .article {margin-top: -1px;}

.section-pagefoot { padding: 27px 0 23px; background: #DBDBDB;}
.pagefoot { padding-right: 360px;
	&_txt {letter-spacing: 0.03px;color: rgb(112, 112, 112);font-style: italic;font-size: 14px;font-weight: 400;line-height: 20px;
		p {padding: 0;}
	}
}
.sale {margin-bottom: 30px;
	&_title {letter-spacing: -0.23px;padding: 0 0 10px 6.3%; color: rgb(174, 11, 114);font-size: 28px;font-weight: 500;line-height: 36px;}
	&_btn { display: flex; text-align: center;background: rgb(143, 213, 166);color: rgb(3, 4, 5);
		&_right {transition: all 0.3s linear;justify-content: center; padding: 10px 28px; white-space: nowrap; background: rgb(59, 178, 115); color: rgb(255, 255, 255);font-size: 20px;font-weight: 700;line-height: 26px;display: flex;align-items: center;}
		&_left { flex: 1;padding: 17px 3% 18px 7%; display: flex;align-items: center;font-weight: 400; line-height: 23px;}
		&:hover {color:#000;
			.sale_btn_right {background: rgb(52, 155, 101);}
		}
	} 
	&1  {background: rgba(235, 235, 235, 0.5); padding: 18px 0 32px; margin-bottom: 35px;
		.sale_btn_txt {margin:0 auto; max-width: 470px;}
		.sale_btn_right { min-width: 35.8%;}
		.sale_btn_left { padding: 17px 4% 18px 4%;}
	}
	&2 {    margin-top: 32px;
		.sale_btn {background: rgb(174, 212, 230);
			&_right {background: rgb(34, 174, 217);}
			&:hover {
				.sale_btn_right {background: rgb(28, 145, 181);}
			}
		}
	}
}

.best {position: sticky; top:0;
	&_title {padding: 10px; color: rgb(255, 255, 255);font-size: 19px;font-weight: 400;line-height: 25px; text-align: center;background: rgb(134, 9, 134); }
	&_list {border-left: 1px solid rgba(0, 0, 0, 0.37); background: #fff;
		&_item {padding: 10px 0 10px 9px; border-bottom: 1px solid rgb(202, 202, 202);display: flex;align-items: center; overflow: hidden;}
		&_img {width: 85px; min-width: 85px; height: 56px;
			img {width:100%;height: 100%;object-fit: cover;}
		}
		&_cont {padding: 0 16px; overflow: hidden; flex-grow:1;}
		&_arr {width: 107px; min-width: 107px; padding-left: 6px; color:rgb(134, 9, 134); height: 84px; border-left: 1px solid rgba(112, 112, 112, 0.37);display: flex;align-items: center;justify-content: center;
			.icon {width:22px;height: 38px;}
		}
		&_title { margin-bottom: 5px; color: rgb(134, 9, 134);font-size: 14px;font-weight: 700;line-height: 20px;
			p {padding: 0;}
		}
		&_link {font-size: 14px;font-weight: 700; line-height: 24px; margin-top: 7px;
			a {text-decoration-line: underline;color: rgb(174, 11, 114);
				&:hover {color: rgb(134, 9, 134);}
			}
		}
		.stars {height: 15px; margin-left:-2px;width: 100px;}
	}
	&_btn { background: #fff;border-left: 1px solid rgba(0, 0, 0, 0.37);border-bottom: 1px solid rgba(0, 0, 0, 0.37);text-align: center; padding: 20px 0;
		.btn {width: 238px;height: 45px;}
	}
}

/* */
@media screen and (min-width: 1280px) {  
	.section-compare .scroll-wrapper { margin-right: -4.7%;}
	.table-wrap:after { min-width: 4.55%; width: 4.6%;}
} 
@media screen and (max-width: 1279px) {   
	.section-inner { padding: 89px 0 29px;}
	.wrapper {padding: 0 30px;}
	.columns_center {padding: 30px 30px 0px 30px;}
	.columns_aside {padding: 30px 30px 47px 30px; min-width: 292px; width: 292px;}
	.cards_item_cont { padding: 34px 29px 23px 26px;}
	.cards_item_special { margin: 26px 0 -5px -210px;} 
	.pagehead {margin-bottom: 0;}
	.review .columns_center { padding: 17px 30px 0px 30px;}
	.review .columns_center .sale { margin-left: -30px;}
	.review_foot {padding: 24px 370px 13px 30px; margin-left: -30px; margin-right: -370px;} 
	.best_list_arr { width: 94px; min-width: 94px;}
	.review_aside {  width: 340px;    min-width: 340px;}
	.sale1 .sale_btn_left, .sale_btn_left { padding: 17px 30px 18px 30px;}
	.sale_btn_right { padding: 10px 25px;}
	.sale_title {padding-left: 30px;}
	.numbs_item {max-width: 140px;}
	.pagefoot { padding-right: 340px;}

}
@media screen and (min-width: 1024px) {  
	.main-wrapper {overflow: visible;}
	.toptxt {display: none;}
	.m-show {display: none;}
}
@media screen and (max-width: 1023px) {  
	.m-hid {display: none;}
	.welcome { padding: 107px 0 46px;}
	.welcome_txt p { padding-bottom: 10px;}
	.breadcrumbs + .article {margin-top:22px;}
	.article {letter-spacing: 0.02em;
		h1 { font-size: 21px; font-weight: 500; padding: 0rem 0 28px 0px;}
		h2 { font-size: 21px; font-weight: 500; padding: 0rem 0 7px 0;}
		h3 { font-size: 20px;}
		h4 { font-size: 18px;} 
		p + h2 { margin-top: -2px;} 
	}
	.smtxt { margin-bottom: 12px;}
	.description {letter-spacing: 0em;
		h1 {font-weight: 400; letter-spacing: 0.05px; padding: 0rem 0 9px 0px;}
	}
	.reviews:not(:first-child) { margin-top: 44px;}
	.reviews {margin:0 -1px;
		&_item {padding: 15px 15px 15px; margin-bottom: 14px; font-size: 14px;
			&_img { margin: 0px 31px 0px 0;width: 170px; min-width: 170px; height: 130px; border-radius: 4px;}
			&_cont {padding-right: 152px;}
			&_title {font-size: 17px;margin-bottom: 6px;} 
			&_rating {width:130px; top: 10px; padding: 0 0 15px;
				&_title {font-size: 17px; margin-bottom: 5px;}
			}
			&_btn {width:130px; top: 89px;
				.btn { gap: 4px;padding: 0 10px; font-size: 15px; font-weight: 600;width: 123.43px; height: 30px;
					.icon {    width: 10px;    height: 9px;}
				}
			}
			&_txt { letter-spacing: 0.05px; font-size: 12px;line-height: 25px;margin-bottom: 2px;}
			.stars {height: 16.5px;width: 111px;}
			&_link a {font-size: 13.86px; font-weight: 600;}
		}
	} 
	.reviews_item:last-child { margin-bottom: 0px;}
	.columns {display: block;
		&_center {padding: 30px 30px 30px 30px;} 
		&_aside {width:100%; padding: 32px 30px 32px 30px;
			h3 { padding-bottom: 4px; letter-spacing: -0.3px;}
		}
	}
	.cards_item_special { margin: 20px 0 -5px -199px;}
	.section-compare { padding: 0px 0 21px;
		.wrapper:before {  margin-bottom: 22px;width: 12.1%;}
		h2 {font-size: 24px; font-weight: 400;letter-spacing: 0.5px; padding: 0rem 0 17px 0;}
	}
	.section-textblock { padding: 10px 0 27px;}
	.textblock-sm {letter-spacing: 0.35px;  }
	.textblock {letter-spacing: 0.38px;
		h3 {font-size: 21px;letter-spacing: 0.45px;     padding-bottom: 6px;   font-weight: 400;}
		p + h3 { margin-top: 0px;}
		h3 + img { margin-top: 22px;}
		img { margin: 0px 0 32px;}
		h4 {font-size: 21px; font-weight: 500;padding-bottom: 1px; margin-bottom: 7px; border-bottom: 3px solid #AE0B72;}
		p + h4 { margin-top: 0px;}
		h2 { font-size: 21px; letter-spacing: 0.45px; padding-bottom: 6px;   font-weight: 400;}
		p + h2 { margin-top: -1px;}
		p:last-child { padding-bottom: 9px;}
	}
	.textblock-bot { 
		p { padding: 0rem 0 1px 0;}
	}
	.section-suggest {padding: 30px 0;
		h2 {font-size: 34px; font-weight: 400;letter-spacing: 0.05px; }
	}
	.suggest_slider { margin: 1px auto 0px;
		>.swiper-scrollbar {position: relative;margin-top:27px;}
	}
	.sideblock:not(:last-child) { margin-bottom: 9px;}
	.posts_item { margin-top: 6px;} 
	.posts_item:last-child { margin-bottom: 5px;} 
	.posts_item_title { margin-bottom: 4px;}
	.posts_item_desc { margin-bottom: 8px;}
	.pageerror_title { margin: 44px 0 3px;}
	.pageerror_txt { font-size: 21px; letter-spacing: -0.35px;}
	.best_list_arr { width: 118px; min-width: 118px;padding-left: 0;}
	.review_aside {  width: 100%;    min-width: 240px;}
	.best {margin:0 auto;max-width:380px;} 
	.best_list {border-right: 1px solid rgba(0, 0, 0, 0.37);}
	.best_btn {border: none;     padding: 30px 0 15px;}
	.review_disc_btn .btn {max-width: 303px;} 
	.pagehead {padding: 20px 0 20px; margin-bottom: -29px;
		h1 {font-size: 21px;}
		&_img {width: 296px; height: 190px;} 
	}
	.pagehead_rating {margin: -6px 0 5px;} 
	.sale {margin-right: -30px;
		&_title {font-size: 21px;line-height: 27px; padding-bottom: 16px;}
	}
	.sale_btn_left {text-align: left; }
	.sale_btn_right {font-size: 19px; padding: 10px 15px;}
	.sale1 .sale_btn_right { min-width: 30.8%;}
	.sale1 .sale_btn_txt { margin: 0 0; max-width: 670px;}
	.sale1 .sale_btn_left, .sale_btn_left { padding: 17px 19px 18px 30px;}
	.sale1 { padding: 18px 0 30px; margin-bottom: 32px;}
	.section-review {
		.smtxt-mob {background: #F2F2F2; padding: 11px 30px 8px; display: block;letter-spacing: 0.4px; text-align: right;font-size: 12px;margin-bottom: 0px; line-height: 23px;
			a {color:#707070; text-decoration: underline;}
		}
	}
	.review .columns_center {  padding: 21px 30px 0px 30px;}
	.review_data {margin:0 0 11px 0; float:none; text-align: left;font-size: 12px; line-height: 19px;
		p {display: inline-block; 
			&:not(:last-child):after {content:' | ';margin: 0 3px;}
		}
	}
	.review_btn {margin: 27px 0 30px;}
	.how {margin-bottom: 31px;padding: 13px 15px 16px;}
	.how_title { font-size: 21px; letter-spacing: 0.5px;}
	.numbs_item { margin-top: 25px; max-width: 130px;}
	.numbs_item_txt {font-size: 18px; line-height: 23px;}
	.numbs_item_icon {margin: 0 auto 10px;}
	.numbs_item_val { margin-bottom: 8px;}
	.review_foot {    padding: 31px 30px 13px 30px;    margin-left: -30px;    margin-top: 15px;    margin-right: -30px;border-top: 10px solid rgba(197, 197, 197, 0.55);}
	.review_disc {   padding: 31px 30px 33px;    margin-bottom: 32px;}
	.review_disc .smtxt {  margin-bottom: 29px;}
	.review_disc_btn {   margin-top: 29px;}
	.welcome404 { padding: 107px 0 27px;}

}
@media screen and (min-width: 768px) { 
	.smtxt-mob {display: none;}
	.welcome_bot, .welcome_cont-mob, .review_product {display: none;}
}
@media screen and (max-width: 767px) { 
	.section-inner { padding: 88px 0 29px;}
	.breadcrumbs { margin-bottom: 10px;}
	.breadcrumbs + .article { margin-top: 21px;}
	.smtxt {display: none;}
	.smtxt-mob {letter-spacing: 0.4px; text-align: right;font-size: 12px;margin-bottom: 20px; line-height: 23px;
		a {color:#707070; text-decoration: underline;}
	}
	.reviews {
		&_item {display: block;padding: 0; overflow: hidden; margin-bottom: 18px;
			&_img {width:100%; position: relative; height: auto;
				img {position: absolute;top:0;left:0;}
				&:before {content:'';display: block;padding-bottom: 42.2%;} 
			}
			&_title {margin-bottom: 7px;}
			&_cont {padding: 12px 23px 25px;} 
			&_bot {display: flex;align-items: center; justify-content: space-between;}
			&_btn {position: static;width:auto;}
			&_rating {position: static;width:auto;border:none;padding: 0 0 8px;
				&_title {display: none;}
			}
			&_txt {-webkit-line-clamp: 4; margin-bottom: 9px;} 
			.stars {margin: 0 0 0 -2px;}
		}
	}
	.article h1 {line-height: 138%;} 
	.description { letter-spacing: 0.4px;
		h1 {text-transform: none; letter-spacing: 0.5px;padding: 0rem 0 6px 0px;}
	}
	.reviews:not(:first-child) { margin-top: 27px;}
	.welcome { padding: 90px 0 16px;}
	.welcome_cont-desc {display: none;}
	.welcome_title {text-align: center; font-size: 20px; font-weight: 500;}
	.welcome_bot {padding: 13px 30px 8px; line-height: 24px; background: rgb(242, 242, 242);    letter-spacing: -0.25px;
		p {    margin-bottom: -1px;padding: 0; color:#000;font-weight: 400;}   
		a {    letter-spacing: 0.4px;text-decoration: underline; font-size: 12px;color:rgb(112, 112, 112);}
	}
	.cards {
		&_item {display: block;
			&_left {border-radius: 4px 4px 0 0;width:100%; padding: 18px 22px 10px;}
			&_logo {justify-content: flex-start; height: 80px; width:54%;}
			&_cont { padding: 8px 22px 12px 22px; display: block; position: static;}
			&_cntr {padding: 0;width:100%;}
			&_right {margin-top: 12px; width:100%; display: flex;align-items: center;justify-content: space-between;}
			&_btn {margin:0;
				.btn {height: 30px; font-size: 15px;    padding: 5px 21px;}
			}
			&_label { top: 21px;}
			&_special { margin:25px 0 3px 0;
				&_left {letter-spacing: 0.6px; font-size: 16px; line-height: 20px; flex-direction: column;    gap: 0px;
					strong {display: block;}  
				}
			}
			&_title {font-weight: bold;font-size: 17px;line-height: 22.33px;}
			.rating_value_txt {display: none;}
			.rating_value_num {font-size: 26.93px; width: 55px; height: 39px;}
			.stars {margin:0 auto;height: 17px;  width: 114px;}
			&_stars {position: absolute;width: 47%;top: 75px;right:0px;}
			&_rating {position: absolute;width: 47%;top: 23px;right:0px;}
			&_txt { margin-bottom: -4px;
				ul li {padding: 0 0 8px 23px;}
			}
			&_review a {line-height: 20px;}
		}
	}
	.cards_item:not(:last-child) { margin-bottom: 18px;}
	.cards_item_special_right {    height: 48px; padding: 0px 10px; width: 48px;
		svg {    width: 17px;  height: 17px;}
	}
	.cards_item_price a svg { margin-left: 4px;}
	.cards_item_special_hid {display: none;}
	.cards_item_label + .cards_item_left {padding-top: 61px;}
	.cards_item_label ~ .cards_item_cont .cards_item_stars {top: 118px;}
	.cards_item_label ~ .cards_item_cont .cards_item_rating {top: 66px;}
	.columns_center { padding: 14px 30px 29px 30px;}
	.section-compare .wrapper:before { width: 25.1%;}
	.section-compare h2 {padding: 0rem 0 15px 0;}
	.textblock {
		h3 { padding-bottom: 7px;} 
	}
	.section-suggest { padding: 33px 0  30px;}
	.section-suggest h2 {line-height: 1.2; margin: 0 8px;}
	.suggest_item { margin: 3px 10px;}
	.columns_aside { padding: 42px 30px 32px 30px;}
	.columns_aside h3 { padding-bottom: 9px;}
	.sideblock:not(:last-child) { margin-bottom: 19px;}
	.posts_item_title { margin-bottom: 9px;}
	.posts_item_desc {  margin-bottom: 3px;} 
	.pageerror_title {  margin: 18px 0 3px;}
	.pageerror_txt {    margin-bottom: 10px;}
	.pagehead {  padding: 31px 0 36px;}
	.pagehead_img {display: none;} 
	.pagehead_rating {margin: 8px 0 9px;}
	.review_product {margin-top: -1px; padding: 0 29px 29px; background: rgba(242, 242, 242,0.73);}
	.product {border: 1px solid rgba(112, 112, 112, 0.34);border-radius: 4px;background: rgba(255, 255, 255,0.92);opacity: 0.92;
		&_title {background: rgba(134, 9, 134,0.92); color: rgb(255, 255, 255);text-align: center;padding: 11px; font-size: 18px;font-weight: 500;line-height: 23px;}
		&_btn {text-align: center; padding: 0 0 15px;
			.btn {font-weight: 600; text-transform: uppercase; width: 239px;font-size: 19px;height: 45px;width: 239px;height: 45px;}
		}
		&_cont {display: flex;align-items: center; text-align: center; padding: 10px 0;}
		&_img {height: 126px; width:60.2%; min-width:60.2%; border-right: 1px solid rgba(112, 112, 112, 0.37);padding: 0 22px;
			img {width: 100%;height: 100%; object-fit: cover;}
		}
		&_rating {border-top: 1px solid rgba(112, 112, 112, 0.37);display: flex;justify-content: space-between;align-items: center; padding: 14px 24px 19px;
			p {padding: 0;color: rgb(0, 0, 0);font-size: 17px;font-weight: 400;line-height: 22.33px;}
		}
		.stars {height: 16.5px; width: 110px; margin-right: -4px;}
		.rating_value {flex-grow:1; display: block;
			&_txt {color: rgb(0, 0, 0);font-size: 17px;font-weight: 400;line-height: 20px;margin: 8px 0 0px;}
			&_num {font-size: 40px;font-weight: 500;line-height: 51px;background: transparent;width:auto;height: auto;color: rgb(134, 9, 134);}
		}
	}
	.review .columns_center {  padding: 31px 30px 0px 30px;}
	.sale_title {font-size: 24px;line-height: 31px;    letter-spacing: -0.1px;    padding-bottom: 11px;}
	.sale_btn {display: block;
		&_right {font-size: 20px; padding: 16px 30px;}
	}
	.sale1 {margin-bottom: 0;     padding: 16px 0 0px;}
	.sale1 .sale_btn_left, .sale_btn_left {padding: 21px 30px 17px; text-align: center;}
	.how {background: rgba(242, 242, 242,0.73); border:none; border-radius: 0;margin:0 -30px 31px;padding: 28px 30px 22px;}
	.numbs {flex-wrap:wrap;
		&_item {     margin-bottom: 6px;   width: 43%;    max-width: 300px;} 
	}
	.review_nav { padding: 31px 0 14px;}
	.review_disc {margin:0 -30px 30px;
		.smtxt {display: block;}
	}
	.review_foot { border-bottom: 0;padding-bottom: 39px;}  
	.review_aside {padding: 0 30px;}
	.best {position: relative;}
	.best_btn { padding: 30px 0 27px;}
	.best_list_arr {    width: 106px;    min-width: 106px;    padding-left: 7px;}

}
@media screen and (max-width: 399px) { 
	.wrapper { padding: 0 20px;	}
	.win {padding: 20px;}
	.columns_center { padding: 14px 20px 29px 20px;}
	.columns_aside { padding: 32px 20px 32px 20px;}
	.cards_item_left {padding: 18px 20px 10px;}
	.cards_item_cont { padding: 8px 20px 12px 20px;}
	.table-scroll .fixed-side { min-width: 140px;}
	.table-scroll td:not(.fixed-side) { width: 160px; min-width: 160px;}
	.review .columns_center {  padding: 21px 20px 0px 20px;}
	.sale_btn_right { padding: 16px 20px;}
	.sale1 .sale_btn_left, .sale_btn_left { padding: 21px 20px 17px;}
	.section-review .smtxt-mob { padding: 11px 20px 8px;} 
	.review_product { padding: 0 20px 20px;} 
	.how {margin: 0 -20px 31px;    padding: 20px 20px 20px;}
	.review .columns_center .sale { margin-left: -20px;}
	.sale {  margin-right: -20px;margin-left: -20px;}
	.review_foot {    padding: 31px 20px 13px 20px;    margin-left: -20px; margin-top: 0px; margin-right: -20px;}
	.review_disc { padding: 21px 20px 23px; margin: 0 -20px 20px;}
	.review_aside { padding: 0 20px;}
	.best_list_arr {    width: 94px;    min-width: 94px;    padding-left: 0px;}


}
